@import "../color-filters";
.projects{
  header{
    margin-bottom: 2.5rem;
  }
  .project-grid{
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 750px) {
      width: 800px;
      justify-content: space-between;
      margin: 0 auto;
      .grid-item{
        width: calc(50% - 2rem);
        margin-left: auto;
        margin-right: auto;
      }
    }


    @media screen and (min-width: 750px) and (max-width: 989px) {
      .grid-item{
        width: calc(48% - 1.5rem);
      }
    }
    @media screen and (max-width: 989px) {
      width: calc(90% - 1.5rem);
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (max-width: 749px) {
      .grid-item{
        width: 100%;
      }
    }


    .grid-item{
      position: relative;
      margin-bottom: 1.5rem;
      padding: 1.5rem 1rem;
      min-height: 400px;
      border-radius: 12px;
      border: 1px solid rgba(8, 255, 251, .4);

      &:last-child{
        margin-left: auto;
        margin-right: auto;
      }

      .project-image{
        width: 100%;
        height: 150px;

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .project-name{
        color: var(--color-variant-2);
        font-size: 1rem;
        margin: 1rem 0;
        text-align: center;
      }

      .project-description{
        font-size: .9rem;
        line-height: 1.5rem;
      }

      .project-links{
        margin-top: .8rem;
        .anchor{
          display: block;
          width: fit-content;
          width: -moz-fit-content;
          margin-left: auto;
          margin-right: auto;
          color: var(--white);
          opacity: .8;
          text-decoration: none;
          transition: ease-in-out .3s;
          font-size: .9rem;

          &:hover{
            opacity: 1;
            cursor: pointer;
          }
          &:not(:last-child) {
            margin-bottom: .4rem;
          }
        }
      }
    }
  }
}

.detail-modal{
  transition: ease-in-out .4s;
  padding: 1.5rem 1rem;
  background: var(--base-bg);
  z-index: 99999;
  &.closed{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: scale(0) translate(-50%, -50%);
  }

  &.open{
    position: fixed;
    top: 0;
    left: 0;
    transform: scale(1) translate(0, 0);
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .detail-container{
    position: relative;

    .close-icon{
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      transition: ease-in-out .3s;
      cursor: pointer;
      filter: $whiteFilter;

      &:hover{
        transform: scale(1.1);
        opacity: .7;
      }
    }

    .heading{
      font-size: 1.2rem;
      text-align: center;
      line-height: 2rem;
      margin-bottom: 1rem;
      color: var(--color-variant-2);
    }


    .heading-alt{
      font-size: 1rem;
      text-align: left;
      color: var(--color-light);
      margin-bottom: 1.5rem;
    }

    .project-images{
      width: 100%;
      overflow: hidden;

      @media screen and (min-width: 650px) {
        height: 250px;
      }

      @media screen and (max-width: 649px) {
        height: 175px;
      }

      .image-slider{
        height: 100%;
        display: flex;
        flex-wrap: nowrap;

        .image-item{

          @media screen and (min-width: 1100px) {
            width: calc(33% - 1.5rem);
          }

          @media screen and (min-width: 650px) and (max-width: 1099px) {
            width: calc(50% - 1.5rem)
          }

          @media screen and (max-width: 649px) {
            width: calc(100%);
          }
          flex: 0 0 auto;
          &:not(:last-child) {
            margin-right: 2rem;
          }

          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .detailed-content{
      margin: 3.5rem auto;
      @media screen and (min-width: 1100px) {
        width: calc(65% - 2.5rem);
      }

      @media screen and  (min-width: 750px) and (max-width: 1099px) {
        width: calc(85% - 2.5rem);
      }
      @media screen and (max-width: 749px) {
        width: 100%;
      }

      .project-desc{
        font-size: .95rem;
        line-height: 2rem;
      }
    }

    .detail-section{
      margin: 2.5rem 0;

      .tech-list{
        display: flex;
        flex-wrap: wrap;

        .tech-item{
          padding: .3rem 1.2rem;
          font-size: .85rem;
          background: rgba(255, 255, 255, .8);
          color: var(--black);
          border-radius: 12px;
          margin-bottom: 1rem;

          &:not(:last-child) {
            margin-right: .7rem;
          }
        }
      }
    }


    .project-features{
      ul{
        padding-left: 2rem;

        li{
          font-size: .95rem;
          opacity: .8;
          line-height: 2rem;
        }
      }
    }

    .link-section{
      text-align: center;
      margin-top: 1.5rem;

      a{
        text-decoration: none;
        font-size: 1rem;
        font-weight: 700;
        transition: ease-in-out .3s;
        color: var(--color-variant-2);
        opacity: .8;

        @media screen and (min-width: 750px) {
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }

        @media screen and (max-width: 749px) {
          display: block;
          width: fit-content;
          width: -moz-fit-content;
          margin: 0 auto 15px;
        }

        &:hover{
          opacity: 1;
        }
      }
    }
  }
}
