.career-path{
  display: flex;
  align-items: center;


  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }

  .content-section{
    position: relative;
    z-index: 2;
    @media screen and (min-width: 1024px) {
      width: calc(55%);
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    p{
      font-size: .95rem;
      line-height: 2rem;

      a{
        color: var(--color-variant-2);
        display: inline-block;
        font-weight: 700;
        width: fit-content;
        width: -moz-fit-content;
        margin: 0 .3rem;
        text-decoration: none;

        &.upwork{
          color: var(--color-variant-2);
        }

        .icon{
          font-size: 17px;
          margin-right: .2rem;
          display: inline-block;
          vertical-align: middle;
        }
      }
      &:not(:last-child){
        margin-bottom: 1.5rem;
      }
    }
  }

  .image-section{
    position: relative;
    padding: 0 2.2rem;
    height: 100%;
    min-height: 450px;

    @media screen and (min-width: 1024px) {
      width: 40%;
      margin-left: auto;
    }

    @media screen and (max-width: 1024px) {
      width: calc(90% - 1rem);
      margin: 0 auto;
    }

    .image-shape{
      position: absolute;
      opacity: .5;
      width: 320px;
      height: 250px;
      max-width: 100%;
      max-height: 100%;
      &.image-rectangle{
        background: #FFAB73;
        top: 0;
        left: 30px;
      }

      &.image-triangle{
        bottom: 0;
        right: 30px;

        .tri-shape{
          position: relative;
          width: 100%;
          height: 100%;

          &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-left: 160px solid transparent;
            border-right: 160px solid transparent;
            border-bottom: 160px solid #57BCFF;
          }

          &:after{
            content: '';
            position: absolute;
            bottom: -70px;
            left: 0;
            width: 0;
            height: 0;
            border-left: 160px solid transparent;
            border-right: 160px solid transparent;
            border-top: 160px solid #57BCFF;
          }
        }
      }
    }

    .main-image{
      width: 320px;
      height: 320px;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      box-shadow: 0 0 2px 10px rgba(179, 255, 254, .01), 0 0 3px 16px rgba(8, 255, 251, .04);

      .image-cover{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        img{
          width: 100%;
          height: 100%;
          border-radius: inherit;
          object-fit: cover;
          filter: grayscale(60%);
        }
        .image-overlay{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .2);
          border-radius: inherit;
        }
      }
    }
  }

}
