.contact-section{
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, .15);

  @media screen  and (max-width: 989px) {
    flex-direction: column;

    .section-item{
      width: 100%;
    }
    .desc-section{
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (min-width: 990px) {
    justify-content: space-between;
    .section-item{
      width: calc(50% - 2rem);
    }
  }

  .desc-section{
    h3{
      font-size: 1.6rem;
      margin-bottom: 1.5rem;
    }

    p{
      font-size: .95rem;
      line-height: 1.5rem;
      opacity: .85;
    }
  }

  .form-section{
    .input-control{
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      input, textarea{
        padding: 10px 28px;
        font-size: .95rem;
        width: 100%;
        border-radius: 8px;
        background: transparent;
        border: 1px solid rgba(179, 255, 254, .3);
        transition: ease-in-out .3s;
        color: rgba(255, 255, 255, .8);
        font-family: 'Sarala', sans-serif;

        &::placeholder{
          color: rgba(255, 255, 255, .5);
        }

        &:focus{
          outline: 0;
          border: 1px solid rgba(179, 255, 254, 1);
        }
      }

      textarea{
        height: 175px;
      }
    }

    .request-feedback{
      margin-bottom: 1.5rem;
      font-size: .85rem;
      &.error{
        color: #ff0000;
      }

      &.success{
        color: var(--color-base);
      }
    }
  }
}
