@keyframes translateLeft {
  from{opacity: 0; transform: translateX(-100%)}
  to{opacity: 1; transform: translateX(0)}
}

@-webkit-keyframes translateLeft {
  from{opacity: 0; transform: translateX(-100%)}
  to{opacity: 1; transform: translateX(0)}
}

@-moz-keyframes translateLeft {
  from{opacity: 0; transform: translateX(-100%)}
  to{opacity: 1; transform: translateX(0)}
}

@-o-keyframes translateLeft {
  from{opacity: 0; transform: translateX(-100%)}
  to{opacity: 1; transform: translateX(0)}
}

@keyframes translateDown {
  from{ opacity: 0; transform: translateY(-100%)}
  to{ opacity: 1; transform: translateY(0)}
}

@-webkit-keyframes translateDown {
  from{ opacity: 0; transform: translateY(-100%)}
  to{ opacity: 1; transform: translateY(0)}
}

@-moz-keyframes translateDown {
  from{ opacity: 0; transform: translateY(-100%)}
  to{ opacity: 1; transform: translateY(0)}
}

@-o-keyframes translateDown {
  from{ opacity: 0; transform: translateY(-100%)}
  to{ opacity: 1; transform: translateY(0)}
}

@-webkit-keyframes translateItem {
  from{opacity: 0; transform: translateY(100%)}
  to{opacity: 1; transform: translateY(0)}
}

@keyframes translateItem {
  from{opacity: 0; transform: translateY(100%)}
  to{opacity: 1; transform: translateY(0)}
}
@-moz-keyframes translateItem {
  from{opacity: 0; transform: translateY(100%)}
  to{opacity: 1; transform: translateY(0)}
}

@-o-keyframes translateItem {
  from{opacity: 0; transform: translateY(100%)}
  to{opacity: 1; transform: translateY(0)}
}

@keyframes fadeItem {
  from{opacity: 0}
  to{opacity: 1}
}
@-webkit-keyframes fadeItem {
  from{opacity: 0}
  to{opacity: 1}
}

@-o-keyframes fadeItem {
  from{opacity: 0}
  to{opacity: 1}
}

@-moz-keyframes fadeItem {
  from{opacity: 0}
  to{opacity: 1}
}
