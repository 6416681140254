.banner-section{
  width: 100vw;
  height: 100vh;
  position: relative;

  .banner-content{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text-outline{
      .outline-text{
        font-size: 2.5rem;
      }
    }

    .animated-content{
      animation: fadeIntoView .25s;
      max-width: calc(90% - 1rem);
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 750px){
        .writer-section{
          padding: 1rem 0;
          text-align: center;

          .main-word{
            justify-content: center;
            font-size: 2.4rem;
          }
        }
      }
      .display-name{
        font-size: 1.2rem;
        text-align: center;
        opacity: 0;
        color: var(--color-variant-2);
        transform: translateY(-20px);
        visibility: hidden;
        transition: ease-in-out .4s;

        &.in-view{
          opacity: .8;
          visibility: visible;
          transform: translateY(0);
        }
      }

      @keyframes fadeIntoView {
        0%{
          opacity: 0;
          transform: translateY(-20px);
        }

        100%{
          opacity: 1;
          transform: translateY(0);
        }
      }

      .display-buttons{
        margin-top: .5rem;
        display: flex;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        transform: translateY(20px);
        transition: ease-in-out .4s;

        &.in-view{
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
  }
}
