@import "../animations";

.skills{
  .link-data{
    .link-item{
      opacity: 0;
      padding: 2rem 1.5rem;
      &.fade-item{
        opacity: 1;
      }
    }
  }
}

.fade-item{
  animation: fadeItem 1s;
}

