@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import "color-filters";
:root{
  --base-bg: #2C4054;
  --text-color: #24588D;
  --black: #000000;
  --white: #ffffff;
  --color-light: #b3fffe;
  --color-variant-2: #08FFFB;
  --color-light-rgb: rgb(179, 255, 254);
  --color-variant-2-rgb: rgb(8, 255, 251);
  --alt-color: #24588D;
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background: var(--base-bg);
  font-family: 'Open Sans', sans-serif;
  color: var(--white);
}

.button{
  padding: .6rem 1.5rem;
  font-size: .95rem;
  background: transparent;
  border: 1px solid rgba(179, 255, 254, .25);
  color: rgba(179, 255, 254, .9);
  text-align: center;
  border-radius: 8px;
  text-decoration: none;
  transition: ease-in-out .3s;
  cursor: pointer;

  &:hover{
    border: 1px solid rgba(179, 255, 254, .9);
  }

  &:disabled{
    opacity: .5;
  }
}

.page-width{
  @media screen and (min-width: 750px) {
    width: calc(90% - 1rem);
  }

  @media screen and (max-width: 749px) {
    width: calc(90% - 1.5rem);
  }
  max-width: 1650px;
  margin: 0 auto;
  padding: 4rem 0;
}

header{
  font-size: 1.35rem;
  font-weight: 700;
  color: var(--white);
  margin-bottom: 1.2rem;
  text-transform: uppercase;
  text-align: center;
}

.footer-section{
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, .2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  opacity: .8;
  margin-top: 1.5rem;

  .icon{
    width: 20px;
    height: 20px;
    margin-right: .5rem;
  }

  span{
    font-size: 1rem;
  }
}



.link-data{
  justify-content: center;
  display: flex;
  flex-wrap: wrap;


  .link-item{
    display: block;
    padding: 1rem .75rem;
    text-decoration: none;
    transition: ease-in-out .3s;
    cursor: pointer;



    &:hover{
      img{
        transform: scale(1.1);
      }

      .link-title{
        opacity: .7;
      }
    }
    img{
      display: block;
      margin: 0 auto 12px;
      @media screen and (min-width: 750px) {
        width: 40px;
        height: 40px;
      }

      @media screen and (max-width: 749px) {
        width: 35px;
        height: 35px;
      }
      transition: ease-in-out .3s;

      &.redux, &.mysql{
        filter: $whiteFilter;
      }
    }

    .link-title{
      font-size: .75rem;
      color: var(--white);
      transition: ease-in-out .3s;
      text-align: center;
    }
  }
}

.work-profiles{
  .link-data{
    opacity: 0;
    &.translate-item{
      opacity: 1;
    }

    .link-item{
      @media screen and (min-width: 750px) {
        &:not(:last-child) {
          margin-right: 1.75rem;
        }
      }

      @media screen and (max-width: 749px) {
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }
  }
}

.translate-item{
  animation: translateItem 1s;
  opacity: 1;
}

.fade-item{
  animation: fadeItem 1s;
}

