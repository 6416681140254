:root{
  --text-color: #FFFFFF;
}
.text-outline{
  width: fit-content;
  width: -moz-fit-content;
  position: relative;

  .outline-text{
    font-size: 100px;
    text-transform: uppercase;
    letter-spacing: 4px;
    &.bg-text{
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: var(--text-color);
      -webkit-text-fill-color: var(--base-bg);
    }

    &.main-text{
      color: var(--text-color);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .span-text{
        position: relative;
        overflow: hidden;
        width: 0;
        border-right: 4px solid var(--text-color);

        &.animate{
          animation: fillWidth 1s;
        }

        span{
          width: var(--outline-width);
          display: block;
        }
      }

      @keyframes fillWidth{
        from{ width: 0}
        to{ width: var(--outline-width)}
      }
    }
  }


}
