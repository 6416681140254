.writer-section{
  opacity: 0;
  padding: 0 1.25rem;
  transition: ease-in-out .15s;
  &.section-visible{
    opacity: 1;
  }


  .writer-desc{
    font-size: 1.25rem;
    font-weight: 300;
  }

  .writer-text{
    min-height: 100px;
    display: flex;
    align-items: center;

    span{
      &.main-word{
        font-size: 2.5rem;
        display: flex;
        flex-wrap: wrap;

        span{
          display: block;

          &:not(:first-child) {
            margin-left: 12px;
          }


          &.line-cursor{
            margin-left: 0;
            width: 2px;
            height: 60px;
            background: rgba(255, 255, 255, .85);
            animation: fluctuate 1s linear infinite;
            display: block;

            @keyframes fluctuate{
              0%{
                background: rgba(255, 255, 255, .1);
              }

              100%{
                background: rgba(255, 255, 255, .85);
              }
            }
          }
        }
      }



    }
  }
}
