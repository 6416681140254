@import "../color-filters";
@import "../animations";
.main-navigation{
  width: 100%;
  padding: 1.75rem 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  &.offset{
    animation: translateDown .65s;
    background: var(--base-bg);
   /* box-shadow: 0 0 4px 4px rgba(8, 255, 251, .35);*/
  }



  .navigation-list{
    display: flex;
    justify-content: center;
    list-style: none;
    padding-left: 0;

    li{
      padding: 0 1.5rem;

      a{
        color: var(--white);
        font-size: .95rem;
        text-decoration: none;
        transition: ease-in-out .3s;
        display: block;
        width: fit-content;
        width: -moz-fit-content;
        margin: 0 auto;
        padding-bottom: .7rem;
        border-bottom: 2px solid transparent;
        position: relative;
        overflow: hidden;

        .bottom-border{
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 2px;
          background: var(--white);
          width: 0;
          transition: ease-in-out .3s;
        }

        &:hover{
        //  color: var(--color-base-dark);

          .bottom-border{
            width: 100%;
          }
        }

      }
    }
  }

  @media screen and (max-width: 749px) {
    display: none;
  }
}

.hamburger-menu{
  width: 60px;
  height: 60px;
  top: 40px;
  right: 20px;
  position: fixed;
  background: var(--alt-color);
  border: 1px solid rgba(36, 88, 141, .5);
  box-shadow: 0 0 2px 2px rgba(179, 255, 254, .15);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--white);
  transition: ease-in-out .3s;
  cursor: pointer;
  z-index: 999;

  &:hover{
    opacity: .7;
  }

  @media screen and (min-width: 750px) {
    display: none;
  }
}

.mobile-navigation{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 1.5rem 1.2rem;
  background: var(--base-bg);
  transition: ease-in-out .5s;
  z-index: 9999;

  @media screen and (min-width: 750px) {
    display: none;
  }

  &.closed{
    transform: translateY(-100%);
  }


  .nav-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: relative;

    .close-icon{
      position: absolute;
      top: 15px;
      right: 10px;
      color: var(--white);
      transition: ease-in-out .3s;
      cursor: pointer;

      &:hover{
        opacity: .7;
        transform: scale(1.2);
      }
      svg{
        filter: $whiteFilter;
        font-size: 24px;
      }
    }
  }

  ul{
    list-style: none;
    padding-left: 0;

    li{
      padding: 1.5rem 0;
      text-align: center;


      a{
        color: var(--white);
        font-size: 1.05rem;
        transition: ease-in-out .3s;
        text-decoration: none;
        display: block;
        padding-bottom: 1rem;
        width: fit-content;
        width: -moz-fit-content;
        margin: 0 auto;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        &:hover{
            color: var(--white);
            border-bottom: 1px solid rgba(255, 255, 255, .4);
        }
      }
    }
  }
}
